import React, { Component } from "react";

import Languages from "./Languages";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDarkTheme: true,
    };
    this.handleChangeBackgroundImage =
      this.handleChangeBackgroundImage.bind(this);
  }

  demoMethod() {
    this.props.sendData(this.state.isDarkTheme);
  }

  handleChangeBackgroundImage(e) {
    e.preventDefault();
    this.setState({
      isDarkTheme: !this.state.isDarkTheme,
    });
    this.demoMethod();
  }

  render() {
    if (this.props.data) {
      var lang = this.props.lang;
      var iAm = this.props.data.iAm;
      var shortDescription = this.props.data.shortDescription;
      var name = this.props.data.name;
      var description = this.props.data.description;
      var home = this.props.data.navButtons.home;
      var about = this.props.data.navButtons.about;
      var resume = this.props.data.navButtons.resume;
      var works = this.props.data.navButtons.works;
      var contact = this.props.data.navButtons.contact;
    }

    const backgroundImageDark = {
      background:
        "#161415 url(../images/header-background-dark2.webp) no-repeat top center",
      backgroundPositionY: "0px",
    };
    const backgroundImageLight = {
      background:
        "#161415 url(../images/header-background-light.webp) no-repeat 35px center",
      backgroundPositionX: "0px",
    };

    return (
      <header
        id="home"
        style={
          this.state.isDarkTheme ? backgroundImageDark : backgroundImageLight
        }
      >
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>

          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>
          <ul id="nav" className="nav">
            <li className="current" id="underlineAnimation">
              <a
                className="smoothscroll"
                href="#home"
                aria-label="Scroll to home section"
              >
                {home}
              </a>
            </li>
            <li id="underlineAnimation">
              <a
                className="smoothscroll"
                href="#about"
                aria-label="Scroll to about section"
              >
                {about}
              </a>
            </li>
            <li id="underlineAnimation">
              <a
                className="smoothscroll"
                href="#resume"
                aria-label="Scroll to resume section"
              >
                {resume}
              </a>
            </li>
            <li id="underlineAnimation">
              <a
                className="smoothscroll"
                href="#portfolio"
                aria-label="Scroll to portfolio section"
              >
                {works}
              </a>
            </li>
            <li id="underlineAnimation">
              <a
                className="smoothscroll"
                href="#contact"
                aria-label="Scroll to contact section"
              >
                {contact}
              </a>
            </li>
            <li className="nav-languages current">
              <Languages
                lang={lang}
                onChangeLanguage={this.props.onChangeLanguage}
              />
            </li>

            {this.state.isDarkTheme ? (
              <li className="mobile-theme-switcher">
                <img
                  width={28}
                  height={28}
                  className="theme-toggle-moon"
                  src="../../images/sun.svg"
                  alt="moon_button_theme_switch"
                  style={{
                    width: "36px",
                    position: "relative",
                    top: "3px",
                  }}
                  onClick={this.handleChangeBackgroundImage}
                />
              </li>
            ) : (
              <li className="mobile-theme-switcher">
                <img
                  width={28}
                  height={28}
                  className="theme-toggle-sun"
                  src="../../images/moon-white.svg"
                  alt="sun_button_theme_switch"
                  style={{
                    width: "36px",
                    position: "relative",
                    top: "3px",
                  }}
                  onClick={this.handleChangeBackgroundImage}
                />
              </li>
            )}
          </ul>
        </nav>
        <div className="row banner">
          <div className="banner-text">
            <h1
              className="responsive-headline"
              style={
                this.state.isDarkTheme
                  ? {
                      color: null,
                      fontFamily: "JetBrains Mono, monospace",
                      fontWeight: "bold",
                    }
                  : {
                      color: "#213343",
                      fontFamily: "JetBrains Mono, monospace",
                      fontWeight: "bold",
                    }
              }
            >
              {iAm} {name}.
            </h1>
            <h2>
              <span
                style={
                  this.state.isDarkTheme
                    ? {
                        color: null,
                        fontWeight: "bold",
                        fontFamily: "JetBrains Mono, monospace",
                      }
                    : {
                        color: "#213343",
                        textShadow: "none",
                        fontWeight: "bold",
                        fontFamily: "JetBrains Mono, monospace",
                      }
                }
              >
                {shortDescription}
              </span>
              <p
                style={
                  this.state.isDarkTheme
                    ? {
                        color: "white",
                        fontFamily: "JetBrains Mono, monospace",
                      }
                    : {
                        color: "#213343",
                        textShadow: "none",
                        fontFamily: "JetBrains Mono, monospace",
                      }
                }
              >
                {description}
                <span
                  class={
                    this.state.isDarkTheme
                      ? "blinker-caret-dark"
                      : "blinker-caret-light"
                  }
                >
                  __
                </span>
              </p>
            </h2>
          </div>
        </div>

        <p
          className="scrolldown"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <a
            className="smoothscroll "
            href="#about"
            aria-label="Scroll to about section"
          >
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
